
var Dropdown = {

    // Init the dropdown so that placeholders shows well
    init: function (scope = 'body') {
        $('.Dropdown', scope).off('change.placholders').on('change.placholders', function() {
            Dropdown.update($(this))
        });
        $('.Dropdown', scope).each(function () { Dropdown.update($(this)) });
        this.initMultiple(scope)
    },

    initMultiple: function (scope) {

        // Make sure dropdown is inited with no values by browser
        $('.Dropdown[multiple] option:selected', scope).prop("selected", false);
        $('.Dropdown[multiple] option[placeholder]', scope).attr("empty", true);

        // Update dropdown based on changes 
        $('.Dropdown[multiple]', scope).on('change.multiple', function() {
            Dropdown.updateMultiple($(this)) 
            Dropdown.triggerChange($(this))
        })

        // Clear selection when pressing clear button
        $('.Dropdown__wrapper').on('click', '.Dropdown__clear', function() {
            const multiple = $(this).siblings('.Dropdown[multiple]')
            Dropdown.resetMultiple(multiple)
            Dropdown.triggerChange(multiple)
        });

        // Permit to select option of a multiple select without having to press CTRL key
        $('.Dropdown[multiple] option:not([disabled])').on('mousedown', function(e) {
            e.preventDefault();
            $(this).prop('selected', !$(this).prop('selected'));
            $(this).closest('.Dropdown[multiple]').trigger('change.multiple')

            return false;
        });

        // Scroll select to top, when focus out
        $('.Dropdown[multiple]', scope).on('blur', function() {
            $(this)[0].scrollTop = 0;
        })
        
        // Note : Scroll select to top due to issue, when scrolling with sticky attribute, it would scroll all way down
        $(window).on("scroll", function() {
            $('.Dropdown[multiple]').each(function() {
                $(this)[0].scrollTop = 0;
            })
        });
    },

    updateMultiple: function (dropdown) {

        // Make sure placeholder is not selected
        $('option[placeholder]', dropdown).prop("selected", false);

        // Set placeholder or concatenated values
        const checkedOptions = Array.from(dropdown.children('option:not([value=""])').filter(":checked"));
        let labels = checkedOptions.length
            ? checkedOptions.map(l => l.innerText).join(', ')
            : $('option[placeholder]', dropdown).attr('placeholder');
        dropdown.children("option[data-type=\"placeholder\"]").text(labels);
        dropdown.attr("title", labels);
        $('option[placeholder]', dropdown).attr('empty', !checkedOptions.length);

        // Set clear button
        const clearBtn = dropdown.siblings('.Dropdown__clear')
        if (checkedOptions.length && clearBtn.length === 0) {
            const closeBtn =  $('<img src="/ressources/img/close_blue.svg" class="Dropdown__clear" />');
            const dropdownWrapper = dropdown.closest('.Dropdown__wrapper')
            closeBtn.appendTo(dropdownWrapper);
        } else if (checkedOptions.length === 0) {
            clearBtn.remove()
        }
    },


    resetMultiples: function (dropdowns) {
        dropdowns.each(function() {
            Dropdown.resetMultiple($(this))
        })
    },

    resetMultiple: function (multiple) {
        $('option', multiple).prop("selected", false);
        Dropdown.updateMultiple(multiple)
    },

    // Update the dropdown style depending on if it is null or not
    update: function (dropdown) {
        dropdown.val() != "" ? dropdown.removeClass('Dropdown--null') : dropdown.addClass('Dropdown--null');
    },
    
    triggerChange: function (filter) {
        // Trigger change manually, as it is not if changing value programmatically
        // this is mainly to handle report section reload on "change" on a filter 
        // help on 'change.$': https://stackoverflow.com/a/13693063/7647916
        filter.trigger('change.$')
    }
}

export default Dropdown;