export const DATE_SEPARATOR = "/";

export const DATE_FORMAT_LITEPICKER =
  "DD" + DATE_SEPARATOR + "MM" + DATE_SEPARATOR + "YYYY";

// This method is made to make the date sortable automatically.
// The strategy here is to put the year first, and we know that the format is switched to yyyy/mm/dd "manually".
export function formatDateForDatableSorting(date) {
  const parts = date.split(" ");
  var datePart = parts[0];
  var timePart = parts[1];
  var fullDate = [datePart.split("/").reverse().join("/"), timePart]
  return fullDate.filter(part => part).join(" ");
}

export function minTwoDigits(n) {
  const number = parseInt(n);
  if(isNaN(number)){
    return "01";
  }
  return number.toString().padStart(2, '0');
}

export function dateFromYearMonthDay(year, month, day) {
  return day + DATE_SEPARATOR + month + DATE_SEPARATOR + year;
}

export function dayFromDate(date) {
  const day = parseInt(date.substring(0,2));
  return isNaN(day) ? "01" : day;
}

export function monthFromDate(date) {
  const month = parseInt(date.substring(3,5));
  return isNaN(month) ? "01" : month;
}

export function yearFromDate(date) {
  const year = parseInt(date.substring(6,10));
  return isNaN(year) ? "2023" : year;
}