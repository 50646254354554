var Slider = {

    _scope: '',
    stepsCount: 1,

    init: function (itemsCount = null) {
        $('.Slider__control').each(function () {
            Slider.initNumberOfDot($(this), itemsCount)
        })
        $('.Slider__arrow').off('click').on('click', function() {
            event.preventDefault();
            Slider._scope = $(this).closest('.Slider__control');
            Slider.changeStep($(this).data('action'));
        });
    },

    initNumberOfDot: function (slider, itemsCount) {
        slider = slider || $('.Slider__control')
        itemsCount = itemsCount ?? $('.Slider[data-slider='+ slider.data('id') +']').children().length
        let dotNumber = Math.ceil(itemsCount / slider.data('items'));
        let i = 0, html = '';
        while (i < dotNumber) {
            html += '<span class="Slider__dot ' + (i == 0 ? 'Slider__dot--active' : '') + '"></span>';
            i++;
        }
        $('.Slider__pageDotCtn', slider).html(html)
    },

    changeStep: function(action) {
        let activeDot = $('.Slider__dot--active', Slider._scope);
        let nextDot = action === 'prev' ? activeDot.prev('.Slider__dot', Slider._scope) : activeDot.next('.Slider__dot', Slider._scope);

        if (nextDot.length > 0) {
            activeDot.removeClass('Slider__dot--active');
            nextDot.addClass('Slider__dot--active');
            let slider = $('.Slider[data-slider='+ Slider._scope.data('id') +']');
            slider.animate({scrollLeft : (action === 'prev' ? '-' :'+') +'='+slider.width()}, 0);
        }
    }
}

Slider.init();

export default Slider;