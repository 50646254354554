import Graph from '../../components/graph/_graph.js';

var Kpi = {
    reloadKpis: function () {
        let promises = [];
        $('.KPI__number').each(function () {
            let path = $(this).data('path');
            let kpi  = $(this);
            // Retrieve data to server
            promises.push(Graph.getData(path).then(function (data) {
                data ? kpi.text((Number((data.value ?? 0).toFixed(2))).toLocaleString())
                     : kpi.text('-');
            }));
        });
        return Promise.all(promises)
    },
}

export default Kpi;