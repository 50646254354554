import Dropdown from "../../dropdown/_dropdown";

//maximum nested filter level
var MAX_FILTER_LEVEL = 2;

var DataBinding = {
    init: function () {
        $('select[data-filter]:not([data-no-filter])').on('change', function() {
            let filterName    = $(this).data('filter');
            let filter        = $(this);
            let notThisFilter = DataBinding.generateNotFilterCondition(filterName, filter);
            
            // Reset to placeholder the filters that have options related to the changed dropdown
            $('select[data-filter]' + notThisFilter + ':has(option[data-'+filterName+']):not([multiple]) option[value=""]').attr("selected", true);
            
            // Reset multiple select as well
            const multiple = $('select[data-filter][multiple]' + notThisFilter + ':has(option[data-'+filterName+'])')
            if (multiple.length) {
                Dropdown.resetMultiples(multiple)
            }

            // Show options of other filters based on query if option are related to the changed filter
            let sortFilterQuery = '[data-'+ filterName+']';
            $.each($('select[data-filter]'+ notThisFilter), function (key, select) {
                let count = 1;
                if (count = MAX_FILTER_LEVEL){
                    return false;
                } else {
                    let currentFilter = $(this).data('filter');
                    let currentValue  = $(this).val();
                    if (currentValue != "")
                        sortFilterQuery += '[data-'+ currentFilter +'='+ currentValue +']';
                    count++;
                }
            });

            $('select[data-filter]' + notThisFilter + ':has(option[data-'+filterName+']) option'+sortFilterQuery).prop('disabled', false);

            // Get the first not null option of the changed filter
            let option = $('option:not([value=""])', filter).first();

            // Creates the query
            sortFilterQuery = '';
            $.each(option.data(), function(i, v) {
                sortFilterQuery += ':not([data-filter='+ i +'])';
            });

            // If dropdown was reset, reset all lower filters
            if ($(this).val() === "") {
                $('select[data-filter]'+sortFilterQuery+' option[value=""]').attr("selected", true);
            }
            // else if a value was selected
            else {
                const values = $(this).find(':selected:not([value=""])').map(function(){
                    return $(this).data();
                });
                if(values.length){
                    const fieldsConcerned = Object.keys(values[0]);
                    const valuesByFields = {};
                    for(const field of fieldsConcerned){
                        valuesByFields[field] = [];
                        for(const value of values){
                            valuesByFields[field].push(value[field]);
                        }
                    }
                    $.each($('select[data-filter]' + notThisFilter), function(key, select) {
                        const dropdown = $(this).filter(notThisFilter + sortFilterQuery);
                        $.each(valuesByFields, function(index, values){
                            const joined = values.map(value => `[data-${index}~=${value}]`).join(',')
                            dropdown.find('option[data-'+index+']:not('+joined+')').prop('disabled', true)
                            DataBinding.sortSelect(dropdown, filterName, notThisFilter);
                        });
                    });
                }
            }
        });
    },

    edit: function() {
        $.each($('#edit_form').find('select[data-filter]:not([data-no-filter])'), function (key, select) {
            let filterName = $(this).data('filter');
            let filter = $(this);
            let notThisFilter = DataBinding.generateNotFilterCondition(filterName, filter);

            $.each($(this).find(':selected:not([value=""])').data(), function(dataIndex, dataValue) {
                $.each($('#edit_form').find('select[data-filter]' + notThisFilter), function (key, select) {
                    let dropdown = $(this).filter(notThisFilter);
                    let currentSubFilter  = $(this).data('filter');
                    let currentSubFilterValue = $('select[data-filter="' + currentSubFilter + '"]').find(':selected:not([value=""])').val();
                    dropdown.find('option[data-'+dataIndex+']:not([data-'+dataIndex+'~='+dataValue+'])').prop('disabled', true);
                    DataBinding.sortSelect(dropdown, filterName, notThisFilter, currentSubFilterValue);
                });
            });
        });
    },

    sortSelect: function (dropdown, filterName, notThisFilter, optionValue) {
        // Sort options in alphabetical order, case unsensitive
        var options = dropdown.find('option');
        options.detach().sort(function (a, b) {
            var at = $(a).text().toLowerCase(),
                bt = $(b).text().toLowerCase();
            return at > bt ? 1 : at < bt ? -1 : 0;
        });
        options.appendTo(dropdown);
        $("option:not(:disabled)", dropdown).prependTo(dropdown);
        // Put the placeholder at the top of all this
        $("option[value='']", dropdown).prependTo(dropdown);

        if (optionValue && optionValue!== undefined) {
            let index = $('option[value='+ optionValue +']', dropdown).index();
            dropdown.prop('selectedIndex', index);
        } else if (optionValue == undefined){
            dropdown.prop('selectedIndex', 0);
        }else{
            $('select[data-filter]:not([data-filter='+filterName+']):has(option[data-'+filterName+'])').prop('selectedIndex', 0);
            $('select[data-filter]'+ notThisFilter + '[data-no-filter]').prop('selectedIndex', 0);
        }
    },

    generateNotFilterCondition: function (filterName, filter) {
        // Additional attribute > not all the filters should responsive (the case for selecting the place and site)
        let filterNotIncluded   = filter.data('filter-not-included');
        let notThisFilter = ':not([data-filter=' + filterName + '])';

        if (filterNotIncluded) {
            let arrayNotIncluded = filterNotIncluded.split(' ');

            $.each(arrayNotIncluded, function(key) {
                notThisFilter += ':not([data-filter=' + arrayNotIncluded[key] + '])';
            });
        }

        return notThisFilter;
    },
}

export default DataBinding;