import Graph from '../../components/graph/_graph.js';
import Kpi from '../../components/kpi/_kpi.js';

const GLOBAL = 'global';

var myGeoJSONPath = '/geodata/countries-land-10km.geo.json';
var worldGeoJSON;

var mapStyle = {
    color: '#fafafa',
    fill: true,
    weight: 0.8,
    fillColor: '#c9dbe9',
    fillOpacity: 1,
    opacity: 1,
    boxShadow: '0 0 15 rgba(0,0,0,0.5)',
};

var mapConfig = {
    style: mapStyle,
};

var activeStyle = {
    fillColor :'#66a6cf',
    color: '#fafafa',
    weight: 1,
    fillOpacity: 1,
};

var highlightedStyle = {
    fillColor :'#9dc8e3',
    color: '#fafafa',
    weight: 1,
    fillOpacity: 1,
};

var defaultStyle = {
    fillColor :'#c9dbe9',
    color: '#c9dbe9',
    weight: 0.8
};

var map;
var geojsonLayer;

let dropdown = $('.Homepage__filter--country');

var Homepage = {

    /**
     * Init all the homepage (map, graphs, kpi, events, ...)
     */
    init: function () {
        Homepage.initMap();

        $('.Homepage__region').on('click', function() {
            Homepage.updateActiveRegion($(this));
        });

        $('.Homepage__filter').on('change', function () {
            Homepage.updateData();
        })

        Kpi.reloadKpis();
        Graph.init();
    },

    /**
     * Make the clicked region active on click
     * @param {jQuery} el Homepage clicked menu region item
     */
    updateActiveRegion: function (el) {

        // Updates the active region menu item 
        $('.Homepage__region').removeClass('Homepage__region--active');
        el.addClass('Homepage__region--active');

        // Resets the coutry filter
        dropdown.val('');

        // Scroll the page to see clearly the map and region menu
        $("html, body").animate({ scrollTop: el.offset().top }, 300);

        Homepage.updateData();
    },

    /**
     * Update the coutry filter based on the clicked region
     * @param {string} regionActive Id of the active region
     */
    updateCountryDropdown: function (regionActive) {
        $('option', dropdown).show();

        // Filter and hide the countries not in the regionActive
        if (regionActive !== GLOBAL) {
            $('option:not([data-region=' + regionActive + '])', dropdown).hide();
        }
    },

    /**
     * Updates all data related components (country filter, map, kpis, graphs, ...)
     */
    updateData: function () {
        let regionActive = $('.Homepage__region--active').data('id') || GLOBAL;

        // Gets data from the global region variable stored in homepage/homepage.html.twig
        let regions = window.regions[regionActive];

        Homepage.updateCountryDropdown(regionActive);
        Homepage.updateMap(regions, regionActive);
        Kpi.reloadKpis();
        Graph.initGraphs(true);
    },

    /**
     * Initializes the homepage map
     */
    initMap: function() {

        // Ajax call to get the geoJSON data
        $.getJSON(myGeoJSONPath, function(data) {

            // Updates the global worldGeoJSON variable
            worldGeoJSON = data;

            // Creates the map
            map = Homepage.createMap();

            // Enable the zoom with scroll when map catches the focus
            map.once('focus', function() { map.scrollWheelZoom.enable(); });

            // Updates the map based on selected data
            Homepage.updateMap(null, GLOBAL);
        })
    },

    /**
     * Creates the Leaflet map
     */
    createMap: function() {
        return L.map('unhcr_mrd_map', {
            zoomSnap: 0.5,
            minZoom: 2,
            maxZoom: 5,
            scrollWheelZoom: false,
            doubleClickZoom: true
        }).setView([14.468721, 16.757483], 2.5);
    },

    /**
     * Updates the map selected country based on clicked country on map
     * @param {Event} e The Leaflet map cliked event
     */
    activateCountry: function (e) {

        // Set the selected country in the dropdown
        let clickedCountryIso = e.target.feature.properties.A3;
        $('option[data-iso=' + clickedCountryIso + ']', dropdown).prop('selected', true);

        // Update all components based on this new selection
        Homepage.updateData();
    },

    /**
     * Update the map with active locations (regions, coutries, ...)
     * @param {array} props Country list to highlight
     * @param {string} regionActive Id of the active region
     */
    updateMap: function(props, regionActive) {
        let regions = props || window.regions.global,
            groupCoords = [], countryCoords,
            countryActive = $('option:selected', dropdown).data('iso'),
            zoom = regionActive === GLOBAL ? 2.5 : 3.5;

        // Remove the previous map layer
        map.eachLayer(function (layer) {
            map.removeLayer(layer)
        });

        // Update the GeoJSON layers, with styles and configuration
        geojsonLayer = L.geoJson(worldGeoJSON, mapConfig).eachLayer(function (layer) {

            let style = defaultStyle,
                iso = layer.feature.properties.A3;
            
            // If the layer is in the region set some style
            if (regions.includes(iso)) {

                // Get the center of the country
                let coords = layer.getBounds().getCenter();

                // If no country selected, highlight all region countries
                style = countryActive == null ? activeStyle : highlightedStyle;

                // Show tooltip on hover with global countryName var, stored in homepage.html.twig file
                layer.bindTooltip(window.countryName[iso], {direction: 'top', className: 'tooltip'});

                // Sets the click event listener on country click
                layer.on({ click: Homepage.activateCountry });

                // If active country, set a specific style and store country coords
                if (layer.feature.properties.A3 === countryActive) {
                    countryCoords = [coords.lat,coords.lng];
                    style = activeStyle;
                }

                groupCoords.push([coords.lat,coords.lng]);
            }

            // Set the style to the country layer
            layer.setStyle(style);
        })

        // Adds the geoJSON to the map
        geojsonLayer.addTo(map);

        // If a country is selected, set specific zoom and coordinates
        if (countryActive != null && countryCoords != null) {
            groupCoords = [countryCoords];
            zoom = 4.5;
        }

        if (groupCoords.length) {
            // Update the map focus with new elements with the fly anmiation
            map.flyTo(new L.LatLngBounds(groupCoords).getCenter(), zoom);
        }
    }
}

$('.Homepage').length ? Homepage.init() : false;

/**
 * Returns the props of the filters
 */
export function getProps() {
    let data = {
        region: $('.Homepage__region--active').data('id'),
        country: $('.Homepage__filter--country').val(),
        requester: 'homepage'
    };
    return data;
};
