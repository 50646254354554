import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getProps as getDashboardProps } from '../../pages/private_dashboard/private_dashboard.js';
import { getProps as getHomepageProps} from '../../pages/homepage/homepage.js';

Chart.plugins.unregister(ChartDataLabels);

var Graph = {

    _palette: [
        "#0072bc",
        "#004B7D",
        "#0062A3",
        "#0071BD",
        "#16a4ff",
        "#2cadff",
        "#64c4ff",
        "#8fd4ff",
        "#fcfeff"
    ],

    _currentRequests: [],

    _chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false,
            labels: {
                generateLabels:  {
                    lineWidth: 3,
                    lineDash: 4
                },
                font: {
                    size: 10
                },
                boxWidth: 20
            },
        },
        title: {
            display: true
        },
        scales: {
            yAxes: [{
                gridLines: {
                    color: "rgba(0, 0, 0, 0.05)",
                },
                ticks: {
                    fontSize: 10,
                    beginAtZero: true,
                    maxTicksLimit: 6,
                    userCallback: function(value, index, values) {
                        return value.toLocaleString();
                    }
                }
            }],
            xAxes: [{
                gridLines: {
                    color: "rgba(0, 0, 0, 0.05)",
                },
                ticks: {
                    fontSize: 9,
                    callback: function(value, index, values) {
                        return (values.length > 9 && value.length > 34) ? value.substring(0,34) + '...' : value;
                    }
                }
            }]
        },
        plugins: {
            datalabels: {
                color: "#004B7D",
                align: 'left',
                display: 'auto',
                formatter: function(value, context) {
                    let max = Math.max(...context.dataset.data)
                    return value === max ? value.toLocaleString() : '';
                },
                opacity: function(context) {
                    return context.active || context.dataset.data[context.dataIndex] === 0 ? 0 : 0.75;
                }
            }
        }
    },

    // Fake data to remove
    _chartData: {
        labels: [""],
        datasets: [
            {
                label: "",
                data: []
            }
        ]
    },

    /**
     * Main controller inits the graphs and the event listeners
     */
    init: function () {

        // Init the grapph
        const promise = Graph.initGraphs(false);

        // Exports to the requested format on event trigger
        $('.ListAction__item[data-action]').on('click', function() {
            let action = $(this).data('action');
            let path   = $(this).data('id');

            switch (action) {
                case 'export-png':
                    Graph.pngExport(Graph.getChartInstance(path));
                    break;
                case 'export-csv':
                    Graph.csvExport(path, Graph.getChartInstance(path));
                    break;
            }
        });

        // Update type of graph chart on event trigger
        $('*[data-action="change-type-chart"]').on('click', function() {
            let canvasId = $('canvas', $(this).closest('.Graph__ctn')).attr('id');
            let graph = $('.Graph[data-path=' + canvasId + ']');
            Graph.changeTypeChart(Graph.getChartInstance(canvasId), $(this).data('typeChart'), graph);
        });
        return promise;
    },

    /**
     * Instanciate or update all graphs in the current page
     *
     * @param {boolean} update True if graphs needs to be updated, false if needs to be instanciated
     */
    initGraphs: function (update = false) {
        let promises = [];
        // Load each graphs
        $('.Graph').each(function() {

            // Retrieve graph configuration in DOM
            let graph   = $(this);
            let path    = graph.data('path'),
                unit    = graph.data('unit'),
                legend  = graph.data('legend'),
                type    = graph.data('type'),
                order   = graph.data('order');

            if (path) {

                // Retrieve data to server
                promises.push(Graph.getData(path).then(function (data) {

                    // Sets the title and tooltip labels number format
                    Graph._chartOptions.tooltips =  {
                        callbacks: {
                            label: (item) => item.yLabel.toLocaleString() + ' ' + unit
                        }
                    };

                    // Enable the legend if asked
                    Graph._chartOptions.legend = legend !== '' ? { position: 'bottom' } : false;

                    // If HTTP::OK
                    if (data && data.datasets[0] != null) {
                        data = order ? Graph.orderData(data) : data;
                        data = Graph.addStyleToData(data, type, graph);
                        Graph.initGraph(graph, data, Graph._chartOptions, update)
                    }
                    // Else if no data
                    else Graph.initGraph(graph, Graph._chartData, Graph._chartOptions, update);
                }));
            }
        });
        return Promise.all(promises)
    },

    /**
     * Returns the data of the requested chart data path
     *
     * @param {string} path Path to retrieve chart dataset through Ajax request
     */
    getData: function (path) {
        return new Promise((resolve, reject) => {
            try {
                let url = Routing.generate(path);
                let data = $('.PD__dashboard').length ? getDashboardProps() : getHomepageProps();
                $.ajax( {
                    url: url,
                    data: data,
                    dataType: 'json',
                    beforeSend : function() {

                        // Abort previous request if still pending
                        if (url in Graph._currentRequests)
                            Graph._currentRequests[url].abort();

                        // Adds current request to pending one
                        Graph._currentRequests[url] = this;

                    },
                    success: function (json) {

                        // Removes the request from pending requests array
                        delete Graph._currentRequests[url];
                        resolve(json);

                    }, error: function () {

                        delete Graph._currentRequests[url];
                        resolve(false);

                    }
                });
            } catch (error) {
                resolve(false);
            }
        });
    },

    /**
     * Order bar chart data DESC
     *
     * @param {Object} data Json array containing json chart labels and data
     */
    orderData: function (data) {
        let arrayOfObj = data.labels.map(function(d, i) {
            return {
                label: d,
                data: data.datasets[0].data[i] || 0
            };
        });

        let sortedArrayOfObj = arrayOfObj.sort(function(a, b) {
            return b.data - a.data;
        });

        let sortedLabels = [];
        let sortedData = [];

        sortedArrayOfObj.forEach(function(d) {
            sortedLabels.push(d.label);
            sortedData.push(d.data);
        });

        data.labels = sortedLabels;
        data.datasets[0].data = sortedData;

        return data;
    },

    /**
     * Instanciate or update a requested graph with specified dataset and options
     *
     * @param {jQuery} graph Graph jQuery container $('.Graph')
     * @param {Object} chartData Data of the graph
     * @param {Object} chartOptions Options of the Chart
     * @param {boolean} update True if graph needs to be updated, false if needs to be instanciated
     */
    initGraph: function (graph, chartData, chartOptions, update = false) {
        let locationLevelLabel = typeof chartData.locationLevel != 'undefined' ? ' ' + chartData.locationLevel : '';
        let timeUnitLabel = typeof chartData.timeUnit != 'undefined' ? ' ' + chartData.timeUnit : '';
        let graphUnit = graph.data('unit');
        graphUnit = (graphUnit && graphUnit.length < 3) ? ' (' + graphUnit + ')' : '';
        chartOptions.title.text = graph.data('title') + locationLevelLabel + timeUnitLabel + graphUnit;

        let labelsEnabled = graph.data('label');

        // If Instanciation
        if (!update) {
            let ctx = $("canvas", graph)[0].getContext("2d");

            Graph.updateLabels(chartData, chartOptions, labelsEnabled);

            window.myBar = new Chart(ctx, {
                type: graph.data('type') || 'bar',
                data: chartData,
                options: chartOptions,
                plugins: labelsEnabled ? [ChartDataLabels] : false,
            });
        }

        // If update of the data
        else {
            let canvasId = graph.data('path');
            let chart = Graph.getChartInstance(canvasId);
            if (chart != undefined) {
                chart.data = Graph.addStyleToData(chartData, chart.config.type || 'bar', graph),
                chart.options.title.text = chartOptions.title.text;
                chart.options = Graph.updateLabels(chartData, chartOptions, labelsEnabled);
                chart.update();
            }
        }

    },

    /**
     * Updates the labels of the graph
     *
     * @param {Object} chartData Data of the graph
     * @param {Object} chartOptions Options of the Chart
     * @param {boolean} labelsEnabled True if labels are enabaled
     */
    updateLabels: function (chartData, chartOptions, labelsEnabled) {

        let max = Math.max(...chartData.datasets[0].data);

        if (labelsEnabled) {
            if (max > 0) chartOptions.scales.yAxes[0].ticks.max = max + max * 0.2;

            chartOptions.scales.yAxes[0].afterTickToLabelConversion = function(scaleInstance) {
                scaleInstance.ticks[0] = null;
                scaleInstance.ticksAsNumbers[0] = null;
            };

            chartOptions.plugins.datalabels.align = 'top';
            chartOptions.plugins.datalabels.anchor = 'end';
            chartOptions.plugins.datalabels.clamp = true;
            chartOptions.plugins.datalabels.clip = false;
        } else {
            delete chartOptions.scales.yAxes[0].ticks.max;
            delete chartOptions.scales.yAxes[0].afterTickToLabelConversion;
        }
        return chartOptions;
    },

    /**
     * Adds style to the requested dataset depending on the chart type
     *
     * @param {Object} data Json array containing json data
     * @param {string} type Type of the chart <'pie'|'bar'|'line'>
     * @param {jQuery} type jQuery DOM element of the Graph
     */
    addStyleToData: function (data, type, graph) {
        for (let [key, dataset] of Object.entries(data.datasets)) {

            // Add UNHCR theme color to chart dataset
            dataset.backgroundColor = Graph.convertHex(Graph._palette[key], 70);
            dataset.borderColor = Graph._palette[key];

            // Sets the line chart style
            if (type === 'bar') {
                dataset.borderWidth = { top:2, right:0.1, bottom:0, left:0.1 };
            }

            // Sets the line chart style
            if (type === 'line') {
                dataset.pointRadius = 2;
                dataset.pointHoverRadius = 8;
                dataset.borderWidth = 2;
                dataset.backgroundColor = 'rgba(0,0,0,0)';
            }

            // Sets the pie chart style if dataset has multiple data values
            if (type === 'pie' && Array.isArray(dataset.data)) {
                let bg = [];
                for (var i = 0; i < dataset.data.length; i++) {
                    bg.push(Graph.convertHex(Graph._palette[key], (i + 1) * 100 / (dataset.data.length + 1)));
                }
                dataset.backgroundColor = bg;
            }
        }
        return data;
    },

    /**
     * Add opacity to an hexadecimal color
     *
     * @param {string} hex Hexadecimal code of a non rgba color
     * @param {number} opacity Opacity to set. Must be between 0 and 100
     */
    convertHex: function (hex, light) {
        hex = hex.replace('#','');
        let r = parseInt(hex.substring(0,2), 16) + light;
        let g = parseInt(hex.substring(2,4), 16) + light;
        let b = parseInt(hex.substring(4,6), 16) + light;

        let result = 'rgb('+r+','+g+','+b+')';
        return result;
    },

    /**
     * Get the chart instance with the canvas id
     *
     * @param {string} canvasId Id of the chart canvas
     */
    getChartInstance: function (canvasId) {
        var chart;
        Chart.helpers.each(Chart.instances, function(instance) {
            if (instance.chart.canvas.id === canvasId) {
                chart = instance;
                return false;
            }
        });
        return chart;
    },

    /**
     * Downloads a png image of the requested Chart
     *
     * @param {Chart} chart Chart object
     */
    pngExport: function (chart) {
        var link = document.createElement('a');
        link.href = chart.toBase64Image();
        link.download = 'graph_export.png';
        link.click();
    },


    /**
     * Changes the chart requested type
     *
     * @param {Chart} chart Chart object
     * @param {string} type Type of the chart <'pie'|'bar'|'line'>
     */
    changeTypeChart: function (chart, type, graph) {
        let ctx = chart.canvas.getContext("2d");
        let config = {
            type: type,
            data: Graph.addStyleToData(chart.data, type, graph),
            options: chart.options
        };
        let temp = jQuery.extend(true, {}, config);

        chart.destroy();
        chart = new Chart(ctx, temp);
    },

    /**
     * Exports the data as a CSV file
     *
     * @param {string} filename Wanted filename for the csv export
     * @param {Object} graph ChartJS instance
     */
    csvExport: function (filename, graph) {
        let multiDimArray = (typeof graph.data.datasets[1] !== 'undefined'),
            csvLabels = Graph.convertDataToCsvLine(graph.data.labels, multiDimArray),
            csvBody  = '';

        graph.data.datasets.forEach(function(dataset) {
            let dataToConvert = dataset.data.slice();
            if (multiDimArray) dataToConvert.unshift(dataset.label);
            csvBody += Graph.convertDataToCsvLine(dataToConvert, false)
        });
        let csv = csvLabels + csvBody;

        if (csv == null) return;

        filename = (filename || 'chart-data') + '.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = 'data:text/csv;charset=utf-8,' + csv;
        }

        let data = encodeURI(csv);
        let link = document.createElement('a');
        link.setAttribute('href', data);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },

    /**
     * Returns a CSV line based on dataset of chart
     *
     * @param {array} data array containg chart data
     * @param {boolean} multiDimArray says if data is a multidimensionnal array
     */
    convertDataToCsvLine: function(data, multiDimArray) {
        let columnDelimiter = ';';
        let lineDelimiter = '\n';
        let result = multiDimArray ? (' ' +  columnDelimiter) : '';
        let ctr = 0;

        if (data == null || !data.length) return null;
        data.forEach(function(item) {
            if (ctr > 0) result += columnDelimiter;
            result += item || 0;
            ctr++;
        });
        result += lineDelimiter;

        return result;
    }
}

export default Graph;