import Litepicker from 'litepicker';
import Graph from "../../components/graph/_graph.js";
import Kpi from "../../components/kpi/_kpi.js";
import DataBinding from "../../components/properties/data_binding/_data_binding";

import { DATE_FORMAT_LITEPICKER } from "../../const/date_format";

if ($("#litepicker").length) {
  var picker = new Litepicker({
    element: document.getElementById("litepicker"),
    firstDay: 1,
    format: DATE_FORMAT_LITEPICKER,
    lang: document.documentElement.lang,
    numberOfMonths: 2,
    numberOfColumns: 2,
    minDate: null,
    maxDate: new Date(),
    minDays: null,
    maxDays: null,
    selectForward: false,
    selectBackward: false,
    splitView: false,
    inlineMode: false,
    singleMode: false,
    autoApply: false,
    showWeekNumbers: false,
    showTooltip: true,
    disableWeekends: false,
    mobileFriendly: true,
    // To enable to add year dropdown
    // dropdowns: {
    //     minYear: 2000,
    //     maxYear: 2020,
    //     months: false,
    //     years: true,
    // },
    setup: (picker) => {
      picker.on("selected", () => {
        PrivateDashboard.reload()
      });
      picker.on("show", () => {
        let date = picker.getDate() || new Date();
        picker.gotoDate(date.setMonth(date.getMonth() - 1)); // Shift to show the previous month

        // To handle date filter clear
        $(".litepicker .button-cancel")
          .unbind()
          .on("click", function () {
            picker.clearSelection();
            PrivateDashboard.reload()
          });
      });
    },
  });
}

var PrivateDashboard = {
  init: function () {
    PrivateDashboard.startLoading();
    let promises = [];
    promises.push(PrivateDashboard.reloadKpis());
    DataBinding.init();
    promises.push(Graph.init());

    // Reload graphs on filter change
    // It was tried to add a namespace here, but it seems to shadow the change signal emitted by the component, that can be namespaced
    $(".PD__filter").on("change", function () {
      PrivateDashboard.reload()
    });

    // Reload graphs on filter change
    $(".PD__exportPdf").on("click", function () {
      PrivateDashboard.printDashboard();
    });

    Promise.all(promises).then((values) => {
      PrivateDashboard.finishLoading();
    })
  },

  reloadKpis: function () {
    PrivateDashboard.hideLocalCurrencyKpi()
    return Kpi.reloadKpis()
  },

  reloadGraphs: function () {
    return Graph.initGraphs(true);
  },

  reload: function () {
    PrivateDashboard.startLoading();
    const promises = [];
    promises.push(PrivateDashboard.reloadKpis());
    promises.push(PrivateDashboard.reloadGraphs());
    $(".PD__filter--referringFacility").length > 0 &&
    $(".PD__filter--referringFacility").val() != ""
      ? PrivateDashboard.hideGraphs()
      : PrivateDashboard.showGraphs();

    Promise.all(promises).then((values) => {
      PrivateDashboard.finishLoading();
    })
  },

  hideLocalCurrencyKpi: function () {
    const props = getProps()
    if (isSet(props.country) || isSet(props.healthSite) || isSet(props.referringFacility) || isSet(props.receivingFacility)) {
      $('.KPI--localCurrency').show();
    } else {
      $('.KPI--localCurrency').hide();
    }
  },

  showGraphs: function () {
    $('.Graph[data-path$="_referring_entity"]').closest(".Graph__ctn").show();
  },

  hideGraphs: function () {
    $('.Graph[data-path$="_referring_entity"]').closest(".Graph__ctn").hide();
  },

  printDashboard: function () {
    window.print();
  },

  finishLoading: function() {
    $(".PDCtn__dashboard").removeClass("PDCtn__dashboard--loading");
  },

  startLoading: function() {
    $(".PDCtn__dashboard").addClass("PDCtn__dashboard--loading");
  },
};

if ($(".PD__dashboard").length) PrivateDashboard.init();

/**
 * Checks property not null and non empty 
 */
function isSet(property) {
  return Array.isArray(property) ? property.filter(n => n).length : property // removes empty strings from array
}

/**
 * Returns the props of the filters
 */
export function getProps() {
  let data = {
    startTime: picker.getStartDate()?.dateInstance,
    endTime: picker.getEndDate()?.dateInstance,
    partner: $(".PD__filter--partner").val(),
    region: $(".PD__filter--region").val(),
    country: $(".PD__filter--country").val(),
    healthSite: $(".PD__filter--healthSite").val(),
    referringFacility: $(".PD__filter--referringFacility").val(),
    receivingFacility: $(".PD__filter--receivingFacility").val(),
    ageCategory: $(".PD__filter--ageCategories").val(),
    benefTypes: $(".PD__filter--beneficiaryTypes").val(),
    gender: $(".PD__filter--gender").val(),
  };
  return data;
}
